import React, { useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

// icn
import { ReactComponent as OTick } from "../../../../../../Assets/Images/icn/OrangeTick.svg";
import { FULL_NAME, currentDayFromMonth_ } from "helpers/common";
import moment from "moment";

const LinkClientPopup = ({ showPopup, closePopup, handleLink, details, clientList }) => {
  const [selectedClient, setSelectedClient] = useState(null);
  const [isLinking, setLinking] = useState(false);
  const onClickLink = () => {
    handleLink(selectedClient, setLinking);
  }

  const onClose = () => {
    setSelectedClient(null);
    closePopup();
  }
  return (
    <>
      <Modal
        show={showPopup}
        onHide={onClose}
        backdrop="static"
        keyboard={false}
        scrollable="true"
        centered
        className="contentPop FormPop"
      >
        <Modal.Header closeButton>
          <Modal.Title className="fw-sbold">Link Appointment to Client</Modal.Title>
        </Modal.Header>
        <Modal.Body className="px-lg-4">
          <div className="commonContent">
            <p>Linking an appointment to an existing client allows you to see that appointment under your client's record and helps you with tracking client appointments over time.</p>
            <ul>
              <li>Type: {details?.title}</li>
              {currentDayFromMonth_(details?.start_time)}, {moment(details?.start_time).format("h:mm A")} - {moment(details?.end_time).format("h:mm A")} 
              <li>Name: {FULL_NAME(details?.first_name, details?.last_name)}</li>
            </ul>
          </div>
          <div className="py-2">
            <p className="fw-bold">Select a client below to link this appointment to:</p>
            <Form.Select
              aria-label="Default select example"
              className="form-control"
              onChange={(e) => setSelectedClient(e.target.value)}
              value={selectedClient}
            >
              <option value="">Select a client</option>
              {clientList && clientList.length > 0 &&
                clientList.map(client => {
                  return (
                    <option value={client.id} key={client.id}>
                      {FULL_NAME(client.first_name, client.last_name)}{` `}
                      {`(${client.email})`}
                    </option>
                  )
                })
              }
            </Form.Select>
          </div>
        </Modal.Body>
        <Modal.Footer className="FormbtnWrpper">
          <Button
            onClick={onClose}
            className="d-flex align-items-center justify-content-center commonBtn"
          >
            Cancel
          </Button>
          <Button
            className="d-flex align-items-center justify-content-center commonBtn"
            onClick={onClickLink}
            disabled={isLinking || !selectedClient}
          >
            {isLinking ? "Please wait..." : "Link"}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default LinkClientPopup;
