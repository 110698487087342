import React, { useState, useEffect, useCallback } from "react";
import { Button, Col, Container, Form, Row, Modal } from "react-bootstrap";
import { Calendar, momentLocalizer } from "react-big-calendar";
import { connect } from 'react-redux';
import moment from "moment/moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import * as actions from 'store/actions';
import { currenDay_, currentDayFromMonth, currentDayFromMonth_, currenDatee_, bookingdatetime, bookingdate, convertTimezone, isDateValid, FULL_NAME, getAppointmentDateTime, convertLocalDateToUTC } from 'helpers/common'
import * as Yup from 'yup';
import Select from 'react-select'

import { appointmentClientList_, appointmentSlots_, appointmentBook_ } from 'store/services/appointmentService';
import { Link } from "react-router-dom";
const momentTZ = require('moment-timezone');

const BookAppointmentPop = ({
  dispatch,
  bookAppointment,
  setBookAppointment,
  appointmentTypeList,
  accountList,
  timeZoneListing,
  setBookApp,
  selectedClient,
  setSelectedClient,
}) => {
  const validationSchema = Yup.object().shape({
    first_name: Yup.string()
      .required('Required'),
    last_name: Yup.string()
      .required('Required'),
    email: Yup.string()
      .required('Required'),
    phone: Yup.string()
      .required('Required')
  });

  const validateData_ = async (data) => {
    try {
      await validationSchema.validate(data, { abortEarly: false });
      return { isValid: true, errors: {} };
    } catch (error) {
      const errors = {};
      error.inner.forEach((err) => {
        errors[err.path] = err.message;
      });
      return { isValid: false, errors };
    }
  };
  const [step, setStep] = useState("5");

  const [clientData_, setClientData_] = useState([]);
  const [appointTypeId, setAppointTypeId] = useState(null);
  const [appointTypeData, setAppointTypeData] = useState(null);
  const [currentSlot, setCurrentSlot] = useState(null);
  const [sending, setSending] = useState(false);
  const [buttonDisable, setButtonDisable] = useState(false);
  const [showTimezoneListing, setShowTimezoneListing] = useState(false);
  const [blockDateSettings, setBlockDateSettings] = useState(null);

  //select days
  const [selectedDay_, setSelectedDay_] = useState(null);
  const [validationErrors, setValidationErrors] = useState({});

  const [stateSlotsVal, setStateSlotsVal] = useState([])

  //client data
  const [clientRes, setClientRes] = useState(selectedClient || null);


  const localizer = momentLocalizer(moment);

  const [selectedDate, setSelectedDate] = useState(new Date()); // State to track the selected date

  const [calendarError, setCalendarError] = useState(null);
  const [schedulerSettings, setSchedulerSettings] = useState({});
  const [bookingResponse, setBookingResponse] = useState(null);

  // check if a client is passed to book a appointment for
  useEffect(() => {
    if (selectedClient) {
      setClientRes(selectedClient);
      setStep("1");
    }
  }, [selectedClient]);

  const checkDateValid = (date, debug = false) => {
    return isDateValid(date, schedulerSettings, debug);
  }

  const handleSelectEvent = event => {
    // Handle event selection if needed
    console.log('Event selected:', event);
  };

  const handleNavigate = date => {
    // Handle navigation to a new date
    if (!checkDateValid(date)) {
      setCalendarError("Please choose a valid date");
      return;
    }
    setSelectedDate(date);
    setCalendarError(null);
  };
  const handleSelectSlot = (slotInfo) => {
    const newSelectedDate = moment(slotInfo.start).toDate();
    if (!checkDateValid(newSelectedDate)) {
      setCalendarError("Please choose a valid date");
      return;
    }
    setSelectedDate(newSelectedDate);
    setCalendarError(null);
  };

  useEffect(() => {
    var parsedDate = moment(selectedDate);
    // Get the day name (full)
    var dayNameFull = parsedDate.format("dddd").toLowerCase();
    setSelectedDay_(dayNameFull);
    if (appointTypeData) {
      onClickAppointType(appointTypeData, dayNameFull)
    }
  }, [selectedDate])

  useEffect(() => {
    if (accountList && accountList.length > 0) {
      let calendarBlocks = accountList.find(setting => setting.data_key === "scheduler_blocked_dates");
      if (!calendarBlocks || !calendarBlocks?.data_set) {
        return;
      }

      calendarBlocks = JSON.parse(calendarBlocks.data_set);
      setBlockDateSettings(calendarBlocks);

      /**
       * set scheduler settings
       */
      let schedulerSettings = {};
      for (let i = 0; i < accountList.length; i++) {
        schedulerSettings[accountList[i]?.data_key] = accountList[i]?.data_set;
      }

      setSchedulerSettings(schedulerSettings);
    }
  }, [accountList])

  const eventStyleGetter = (event, start, end, isSelected) => {
    const style = {
      backgroundColor: event.color,
      borderRadius: "5px",
      opacity: 0.8,
      color: "white",
      border: "0px",
      display: "block",
      width: "100%",
    };
    return {
      style,
    };
  };

  const Trips = [
    {
      title: "Trip to Paris",
      start: new Date(2023, 6, 15), // July 15, 2023
      end: new Date(2023, 6, 20), // July 20, 2023
    },
    {
      title: "Beach Vacation",
      start: new Date(2023, 7, 5), // August 5, 2023
      end: new Date(2023, 7, 12), // August 12, 2023
    },
    // Add more trips as needed
  ];
  const [timeZoneValue, setTimeZoneValue] = useState(moment.tz.guess());
  const [bookingValues, setBookingValues] = useState({
    client_id: clientRes ? clientRes?.id : "",
    appointment_type: "",
    first_name: clientRes ? clientRes?.first_name : "",
    last_name: clientRes ? clientRes?.last_name : "",
    email: clientRes ? clientRes?.email : "",
    phone: clientRes ? clientRes?.phone : "",
    start_time: "",
    end_time: "",
    appointment_timezone: "",
    appointment_timezone_client: 'no',
    duration: "",
    client_appt_tz: timeZoneValue,
  })
  const handleBookAppointment = () => {
    setBookAppointment(!bookAppointment);
    setStep("5")
    setBookApp(true)
    setClientRes(null);
    if (typeof setSelectedClient == "function") {
      setSelectedClient(null);
    }
  };
  const getClientData = (client) => {
    for (let val of clientData_) {
      if (parseInt(client) == val.id) {
        setClientRes(val);
      }
    }
    setStep("1")
  }
  const onClickAppointType = async (data, day) => {
    try {
      setAppointTypeId(data?.id);
      setAppointTypeData(data);
      if(checkDateValid(selectedDate)) {
        let finalObj = {
          day: day,
          duration: parseInt(data?.duration),
          hours: data?.available_working_hours,
          // booking_date: convertLocalDateToUTC(selectedDate, "YYYY-MM-DD")
          booking_date: moment(selectedDate).format("YYYY-MM-DD"),
        }
        dispatch(actions.persist_store({ loader: true }));
        // setButtonDisable(true)
        let res = await dispatch(appointmentSlots_(data?.id, finalObj));
        dispatch(actions.persist_store({ loader: false }));
        // setButtonDisable(false)
        setStateSlotsVal(res?.body?.slots) 
      }
      setStep("2");
    } catch (err) {
      console.log(err);
      // setButtonDisable(false)
      dispatch(actions.persist_store({ loader: false }));
    }
  };

  const slotselctForBooking = (slotsVal) => {
    try {
      setCurrentSlot(slotsVal);
      setStep(3)
    } catch (err) {
      console.log(err);
      dispatch(actions.persist_store({ loader: false }));
    }
  }

  const handleChangeBooking_ = useCallback(
    name => evt => {
      const value = evt.target.value;
      setBookingValues(prevState => ({
        ...prevState,
        [name]: value,
      }));
      setValidationErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
    },
    [bookingValues, clientRes]
  );
  useEffect(() => {
    if (accountList && accountList.length > 0) {
      for (let timeZone of accountList) {
        if (timeZone.data_key === 'scheduler_timezone') {
          if (timeZone.data_set && timeZone.data_set != "disable") {
            setTimeZoneValue(timeZone.data_set);
          }
        }
      }
    }
  }, [])

  /**
   * Update client timezone to be the same as practitioner timezone by default
   */
  useEffect(() => {
    if(timeZoneValue) {
      setBookingValues((prevState) => ({
        ...prevState,
        client_appt_tz: timeZoneValue,
      }))
    }
  }, [timeZoneValue])

  useEffect(() => {
    setBookingValues((prevState) => ({
      ...prevState,
      client_id: clientRes ? clientRes?.id : "",
      first_name: clientRes ? clientRes?.first_name : "",
      last_name: clientRes ? clientRes?.last_name : "",
      email: clientRes ? clientRes?.email : "",
      phone: clientRes ? clientRes?.phone : "",
    }))
  }, [clientRes])

  const bookAppointment_ = async () => {
    try {
      const validation = await validateData_(bookingValues);
      if (validation.isValid) {
        // let zone ;
        // for(let timeZone of accountList){
        //   if(timeZone.data_key === 'scheduler_timezone'){
        //     zone  = timeZone.data_set
        //   }

        // }
        let startDateTime = currentSlot ? bookingdatetime(selectedDate) + ' ' + currentSlot.start_time : '';
        let endDateTime = currentSlot ? bookingdatetime(selectedDate) + ' ' + currentSlot.end_time : '';
        let obj = {
          appointment_type: appointTypeId,
          start_time: getAppointmentDateTime(selectedDate, currentSlot.start_time, timeZoneValue),
          end_time: getAppointmentDateTime(selectedDate, currentSlot.end_time, timeZoneValue),
          appointment_timezone: timeZoneValue,
          duration: appointTypeData ? appointTypeData.duration : 0,
          first_name: bookingValues.first_name ? bookingValues.first_name : clientRes?.first_name,
          last_name: bookingValues.last_name ? bookingValues.last_name : clientRes?.last_name,
          phone: bookingValues.phone ? bookingValues.phone : clientRes?.phone,
          email: bookingValues.email ? bookingValues.email : clientRes?.email,
          client_id: bookingValues.client_id,
          is_paid_appointment: appointTypeData?.is_paid_appointment,
          // booking_date: momentTZ.tz(moment(), timeZoneValue).toDate(),
          booking_date: moment().format("YYYY-MM-DD"),
          client_appt_tz: bookingValues.client_appt_tz,
        }
        obj = JSON.stringify(obj)
        setSending(true)
        setButtonDisable(true)
        dispatch(actions.persist_store({ loader: true }));
        let response = await dispatch(appointmentBook_(obj));
        if(response.success === true && response.body) {
          setBookingResponse(response.body);
        }
        setSending(false)
        setStep('4')
        setBookingValues({})
        setButtonDisable(false)
        setBookApp(true)
        dispatch(actions.persist_store({ loader: false }));
      } else {
        setValidationErrors(validation.errors);
      }
    } catch (err) {
      setSending(false)
      setButtonDisable(false)
      dispatch(actions.persist_store({ loader: false }));
      console.log(err, "==============================");
    }
  };

  const handleChangClose = () => {
    setStep('5')
    setAppointTypeData(null)
    setAppointTypeId(null)
    setCurrentSlot(null)
    setBookingValues({})
    setClientRes(null)
    setButtonDisable(false)
    setBookApp(true)
  }
  //get client list
  async function getClientList() {
    try {
      let res = await dispatch(appointmentClientList_());
      setClientData_(res?.body)
    } catch (err) {
      console.log(err);
    }
  }
  useEffect(() => {
    getClientList()
  }, [bookAppointment])

  const checkIfValidDay = (date) => {
    try {
      if (!blockDateSettings) {
        return;
      }
      let isValid = checkDateValid(date);
      if (!isValid) {
        return {
          className: "blocked-day"
        }
      }

    } catch (err) {
      // console.log("Error validating date", err);
    }
  }

  return (
    <>
      <Modal
        show={bookAppointment}
        onHide={handleBookAppointment}
        backdrop="static"
        keyboard={false}
        centered
        size="lg"
        scrollable="true"
      >
        <Modal.Body>
          <div
            className="px-3"
            style={{ background: "#f2f2f2", minHeight: "100vh" }}
          >
            <Row className="justify-content-center">
              <Col className="my-2" lg="10">
                {step == "5" ? (
                  <>
                    <div className="commenContent commonCardBox px-lg-4 p-3 bg-white">
                      <div className="top py-2 border-bottom bg-white ">
                        <h2 className="m-0">
                          Select a client below to book a new appointment with:
                        </h2>
                      </div>
                      <div className="CardBody py-3">
                        <div className="py-2">
                          <div
                            className="commonCardBox p-3"
                            style={{ background: "#f6f6f6" }}
                          >
                            <div className="content">
                              <div className="py-2">
                                <Col lg="12" className="my-2">
                                  <Form.Select
                                    aria-label="Default select example"
                                    className="form-control"
                                    name="client_id"
                                    onChange={handleChangeBooking_("client_id")}
                                  >
                                    <option value="" disabled selected>--Select a client--</option>
                                    {clientData_.length > 0 && clientData_.map((client, i) => {
                                      if (client.email) {
                                        return (
                                          <option key={client.id} value={client.id}>
                                            {`${FULL_NAME(client.first_name, client.last_name)} (${client.email})`}
                                          </option>
                                        )
                                      }
                                    })}
                                  </Form.Select>
                                </Col>
                              </div>
                              <div className="py-2">
                                <div className="btnWrp mt-2 FormbtnWrpper">
                                  <Button
                                    onClick={() => getClientData(bookingValues?.client_id)}
                                    className="d-flex align-items-center justify-content-center commonBtn "
                                    disabled={bookingValues?.client_id == '' ? true : false}
                                  >
                                    Select
                                  </Button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                ) : step == "1" ? (
                  <>
                    <div className="commenContent commonCardBox px-lg-4 p-3 bg-white">
                      <div className="top py-2 border-bottom bg-white ">
                        <h2 className="m-0">
                          Book an Appointment with {clientRes?.first_name}
                        </h2>
                        <p className="m-0 py-2">
                          Please select the type of appointment that you would
                          like to make to view the available dates/times.
                        </p>
                      </div>
                      <div className="CardBody py-3">
                        {(appointmentTypeList && appointmentTypeList.length > 0) ?
                          appointmentTypeList.map((list_, i) => {
                            return (
                              <div className="py-2" key={i}>
                                <div
                                  className="commonCardBox p-3"
                                  style={{ background: "#f6f6f6" }}
                                >
                                  <div className="top py-2">
                                    <h4 className="m-0 fw-sbold">{list_.title}</h4>
                                    <span className="text-muted m-0">
                                      ({list_.duration} minutes)
                                    </span>
                                  </div>

                                  <div className="content">
                                    {list_.is_paid_appointment === 'yes' &&
                                      <div className="py-2">
                                        <h6 className="m-0">Price : {list_.currency_sign}{list_.price} ({list_.currency_code})</h6>
                                      </div>
                                    }
                                    <div className="py-2">
                                      <h6 className="m-0">Description</h6>
                                      <p className="m-0">
                                        {list_.description}
                                      </p>
                                    </div>
                                    <div className="py-2">
                                      <div className="btnWrp mt-2 FormbtnWrpper">
                                        <Button
                                          // onClick={() => setStep("2")}
                                          onClick={() => onClickAppointType(list_, selectedDay_)}
                                          // disabled={buttonDisable ? true :  false}
                                          className="d-flex align-items-center justify-content-center commonBtn "
                                        >
                                          Select
                                        </Button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )
                          })
                          :
                          <>
                            <p className="m-0">
                              There are currently no Appointment Types to choose from for this scheduler.
                            </p>
                          </>
                        }
                      </div>
                    </div>
                  </>
                ) : step == "2" ? (
                  <>
                    <>
                      <div className="commenContent commonCardBox px-lg-4 p-3 bg-white">
                        <div className="top py-2 border-bottom bg-white ">
                          <h2 className="m-0 mb-2">
                            Book an Appointment with {clientRes?.first_name}
                          </h2>
                          <p className="m-0">
                            Select a highlighted date in the calendar to view
                            available times below.
                          </p>
                          <p className="m-0">
                            All dates/times are in <b> {timeZoneValue}</b>{" "}
                            time zone.
                          </p>
                          <ul className="list-unstyled ps-0 mb-0 d-flex align-items-center py-2 gap-10">
                            {/* <li className="">
                              <Button
                                className="themeLink fw-sbold border-0 p-0"
                                variant="transparent"
                              >
                                Change Time Zone
                              </Button>
                            </li> */}
                            <li className="">
                              <Button
                                onClick={() => setStep("5")}
                                className="themeLink fw-sbold border-0 p-0"
                                variant="transparent"
                              >
                                Start Over
                              </Button>
                            </li>
                          </ul>
                        </div>
                        <div className="py-2">
                          <h6 className="m-0">{appointTypeData?.title}</h6>
                        </div>
                        <div className="py-2">
                          <h6 className="m-0">Duration : {appointTypeData?.duration} minutes</h6>
                        </div>
                        {appointTypeData?.is_paid_appointment === 'yes' &&
                          <div className="py-2">
                            <h6 className="m-0">Price : {appointTypeData?.currency_sign}{appointTypeData?.price} ({appointTypeData?.currency_code})</h6>
                          </div>
                        }
                        <div className="CardBody py-3">
                          <Row>
                            <Col lg="12" className="my-2">
                              {/* <Calendar
                                localizer={localizer}
                                events={Trips}
                                eventPropGetter={eventStyleGetter}
                                startAccessor="start"
                                endAccessor="end"
                                style={{ height: 500 }}
                              /> */}
                              <Calendar
                                localizer={localizer}
                                events={Trips}
                                eventPropGetter={eventStyleGetter}
                                startAccessor="start"
                                endAccessor="end"
                                onSelectEvent={handleSelectEvent}
                                defaultDate={selectedDate}
                                views={['month']}
                                onNavigate={handleNavigate}
                                style={{ height: 500 }}
                                selectable
                                selected={selectedDate}
                                onSelectSlot={handleSelectSlot}
                                dayPropGetter={checkIfValidDay}
                              // dayPropGetter={(date) => {
                              //   if(new Date(date) < new Date()) {
                              //     return {
                              //       className: "past-day"
                              //     }
                              //   }
                              // }}
                              />
                              {calendarError &&
                                <p className="invalid-feedback d-block">{calendarError}</p>
                              }
                            </Col>
                            <Col lg="12" className="my-2">
                              <div className="commonContent">
                                <h4 className="m-0 py-2">
                                  Open Times on {currentDayFromMonth_(selectedDate, timeZoneValue)}
                                </h4>
                                {timeZoneValue && (
                                  <p>All dates/times are in <strong>{timeZoneValue}</strong> time zone.</p>
                                )}
                                <ul className="list-unstyled ps-0 mb-0 py-2">
                                  {stateSlotsVal.length > 0 ? (
                                    <>
                                      {stateSlotsVal.map((slot, i) => {
                                        return (
                                          <li className="py-1" key={i}>
                                            <div
                                              className="commonCardBox p-2 d-flex align-items-center justify-content-between gap-10 flex-wrap"
                                              style={{ background: "#f6f6f6" }}
                                            >
                                              <h6 className="m-0 py-2">
                                                {slot.start_time} to {slot.end_time} on {currentDayFromMonth(selectedDate, timeZoneValue)}
                                              </h6>
                                              <Button
                                                onClick={() => slotselctForBooking(slot)}
                                                className="d-flex align-items-center justify-content-center commonBtn btnSm px-4"
                                                disabled={slot?.is_booked}
                                              >
                                                Select
                                              </Button>
                                            </div>
                                          </li>
                                        )
                                      })}
                                    </>
                                  ) : (
                                    <p className="m-0">No slots found, please choose another date/time.</p>
                                  )}
                                </ul>
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </>
                  </>
                ) : step == "3" ? (
                  <>
                    <div className="commenContent commonCardBox px-lg-4 p-3 bg-white">
                      <div className="top py-2 border-bottom bg-white ">
                        <h2 className="m-0">
                          Book an Appointment with {clientRes?.first_name}
                        </h2>
                        <p className="m-0 py-2">Finalize your appointment.</p>
                        <ul className="list-unstyled ps-0 mb-0 d-flex align-items-center py-2 gap-10">
                          <li className="">
                            <Button
                              onClick={() => setStep("5")}
                              className="themeLink fw-sbold border-0 p-0"
                              variant="transparent"
                            >
                              Start Over
                            </Button>
                          </li>
                        </ul>
                      </div>
                      <div className="CardBody py-3">
                        <div className="py-2">
                          <div
                            className="commonCardBox p-3"
                            style={{ background: "#f6f6f6" }}
                          >
                            <div className="top py-2">
                              <h4 className="m-0 fw-sbold">{appointTypeData?.title}</h4>
                              <span className="text-muted m-0">
                                ({appointTypeData?.duration} minutes)
                              </span>
                              {appointTypeData?.is_paid_appointment === 'yes' &&
                                <div className="py-2">
                                  <h6 className="m-0">Price : {appointTypeData?.currency_sign}{appointTypeData?.price} ({appointTypeData.currency_code})</h6>
                                </div>
                              }
                              <h6 className="m-0 fw-sbold">
                                {currentSlot?.start_time} to {currentSlot?.end_time} on {currentDayFromMonth(selectedDate, timeZoneValue)}
                              </h6>
                            </div>
                            <div className="content">
                              <div className="py-2">
                                <p className="m-0 py-2">
                                  Please provide the following information for your appointment.
                                </p>
                                <Row>
                                  <Col lg="12" className="my-2">
                                    <label
                                      htmlFor=""
                                      className="form-label m-0 fw-sbold"
                                    >
                                      First Name
                                    </label>
                                    <input
                                      type="text"
                                      name="first_name"
                                      class="form-control"
                                      onChange={handleChangeBooking_("first_name")}
                                      value={bookingValues.first_name}
                                    />
                                    {validationErrors.first_name && (
                                      <div className="text-danger">{validationErrors.first_name}</div>
                                    )}
                                  </Col>
                                  <Col lg="12" className="my-2">
                                    <label
                                      htmlFor=""
                                      className="form-label m-0 fw-sbold"
                                    >
                                      Last Name
                                    </label>
                                    <input
                                      type="text"
                                      name="last_name"
                                      class="form-control"
                                      onChange={handleChangeBooking_("last_name")}
                                      value={bookingValues.last_name}
                                    />
                                    {validationErrors.last_name && (
                                      <div className="text-danger">{validationErrors.last_name}</div>
                                    )}
                                  </Col>
                                  <Col lg="12" className="my-2">
                                    <label
                                      htmlFor=""
                                      className="form-label m-0 fw-sbold"
                                    >
                                      Email
                                    </label>
                                    <input
                                      type="email"
                                      name="email"
                                      class="form-control"
                                      onChange={handleChangeBooking_("email")}
                                      value={bookingValues.email}
                                    />
                                    {validationErrors.email && (
                                      <div className="text-danger">{validationErrors.email}</div>
                                    )}
                                  </Col>
                                  {appointTypeData?.location &&
                                    <Col lg="12" className="my-2">
                                      <label
                                        htmlFor=""
                                        className="form-label m-0 fw-sbold"
                                      >
                                        Location
                                      </label>
                                      <input
                                        type="text"
                                        name="location"
                                        class="form-control"
                                        disabled
                                        value={appointTypeData?.location === 'by_phone' ? 'By Phone' : appointTypeData?.location === 'in_person' ? 'In Person' : appointTypeData?.location === 'video_zoom' ? 'Video/Zoom' : ''}
                                      />
                                    </Col>
                                  }
                                  <Col lg="12" className="my-2">
                                    <label
                                      htmlFor=""
                                      className="form-label m-0 fw-sbold"
                                    >
                                      Phone #
                                    </label>
                                    <input
                                      type="number"
                                      name="phone"
                                      class="form-control"
                                      placeholder="+11234567890"
                                      onChange={handleChangeBooking_("phone")}
                                      value={bookingValues.phone}
                                      max={15}
                                    />
                                    <p className="text-muted">Example format: +11234567890</p>
                                    {validationErrors.phone && (
                                      <div className="text-danger">{validationErrors.phone}</div>
                                    )}
                                  </Col>
                                  <Col lg="12" className="my-2">
                                    <label
                                      htmlFor=""
                                      className="form-label m-0 fw-sbold"
                                    >
                                      Client in a different time zone?
                                    </label>
                                    <br />
                                    <div className="row">
                                      <div className="col-md-6">
                                        <input
                                          type="radio"
                                          name="appointment_timezone_client"
                                          class="form-radio"
                                          onChange={handleChangeBooking_("appointment_timezone_client")}
                                          value="no"
                                          checked={bookingValues.appointment_timezone_client === 'no' ? true : false}
                                        />
                                        &nbsp;<label
                                          htmlFor=""
                                          className="form-label m-0 fw-sbold"
                                        >
                                          No
                                        </label>
                                      </div>
                                      <div className="col-md-6">
                                        <input
                                          type="radio"
                                          name="appointment_timezone_client"
                                          class="form-radio"
                                          onChange={handleChangeBooking_("appointment_timezone_client")}
                                          value="yes"
                                          checked={bookingValues.appointment_timezone_client === "yes" ? true : false}
                                        />
                                        &nbsp;<label
                                          htmlFor=""
                                          className="form-label m-0 fw-sbold"
                                        >
                                          Yes
                                        </label>
                                      </div>
                                    </div>
                                  </Col>
                                  {bookingValues.appointment_timezone_client === 'yes' &&
                                    <>
                                      <p>Your appointment will still be on {currentSlot?.start_time} to {currentSlot?.end_time} on {currentDayFromMonth(selectedDate, timeZoneValue)} but we will notify your client of the appointment using the time zone you select below for them:</p><br />
                                      <label
                                        htmlFor=""
                                        className="form-label m-0 fw-sbold"
                                      >
                                        Client's time zone: &nbsp;{bookingValues.client_appt_tz || timeZoneValue} <Link onClick={() => setShowTimezoneListing(true)}>&nbsp;&nbsp;change</Link>
                                      </label>
                                      {showTimezoneListing && accountList.length > 0 &&
                                        accountList.map((settingList, i) => {
                                          if (settingList.data_key === "scheduler_timezone") {
                                            return (
                                              <Col lg="12" className="my-2">
                                                <label
                                                  htmlFor=""
                                                  className="form-label m-0 fw-sbold"
                                                >
                                                </label>
                                                <Select
                                                  defaultValue={
                                                    (function () {
                                                      let defaultTimezone = timeZoneListing.find((zone, i) => settingList.data_set === zone.timezone)
                                                      if (!defaultTimezone) {
                                                        return null;
                                                      }
                                                      return {
                                                        value: defaultTimezone?.timezone,
                                                        label: defaultTimezone?.timezone,
                                                      };
                                                    })()
                                                  }
                                                  options={(function () {
                                                    return timeZoneListing.map(
                                                      (zone, i) => {
                                                        return ({
                                                          value: zone.timezone,
                                                          label: zone.timezone
                                                        })
                                                      }
                                                    )
                                                  })()}
                                                  onChange={(timezone) => {
                                                    setBookingValues(prevState => ({
                                                      ...prevState,
                                                      ["client_appt_tz"]: timezone?.value,
                                                    }));
                                                  }}
                                                />
                                                {/* <Form.Select
                                              aria-label="Default select example"
                                              className="form-control"
                                              name="client_appt_tz"
                                              onChange={handleChangeBooking_(
                                                "client_appt_tz"
                                              )}
                                            >
                                              <option value="" disabled>
                                                --Select Time Zone--
                                              </option>
                                            {timeZoneListing.length > 0 &&
                                              timeZoneListing.map(
                                                (zone, i) => {
                                                  return (
                                                    <option
                                                      value={zone.timezone}
                                                      selected={
                                                        settingList.data_set ===
                                                        zone.timezone
                                                          ? "selected"
                                                          : ""
                                                      }
                                                    >
                                                      {zone.timezone}
                                                    </option>
                                                  );
                                                }
                                              )}
                                            </Form.Select> */}
                                              </Col>
                                            )
                                          }
                                        })}
                                    </>
                                  }
                                  {appointTypeData?.is_paid_appointment === 'yes' ? (
                                    <Col lg="12" className="my-2">
                                      Payment of {appointTypeData?.currency_sign}{appointTypeData?.price} ({appointTypeData?.currency_code}) is required to finalize this booking.
                                      <div className="FormbtnWrpper mt-2">
                                        <Button
                                          type="submit"
                                          disabled={buttonDisable ? true : false}
                                          // onClick={() => setStep("6")}
                                          onClick={() => bookAppointment_()}
                                          className="d-flex align-items-center justify-content-center commonBtn btnSm"
                                        >
                                          {buttonDisable ? 'Wait...' : 'Finalize Booking'}
                                        </Button>
                                      </div>
                                    </Col>
                                  ) : (
                                    <Col lg="12" className="my-2">

                                      <div className="FormbtnWrpper mt-2">
                                        <Button
                                          type="submit"
                                          disabled={buttonDisable ? true : false}
                                          onClick={() => bookAppointment_()}
                                          className="d-flex align-items-center justify-content-center commonBtn btnSm"
                                        >
                                          {buttonDisable ? 'Wait...' : 'Finalize Booking'}
                                        </Button>
                                      </div>
                                    </Col>
                                  )}
                                </Row>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                ) : step == "4" ? (
                  <>
                    <div className="commenContent commonCardBox px-lg-4 p-3 bg-white">
                      <div className="top py-2 border-bottom bg-white ">
                        <h2 className="m-0">
                          Your Appointment with NTA Academics Team
                        </h2>
                        <p className="m-0 py-2 themeLink fw-sbold">
                          Appointment confirmed!
                        </p>
                      </div>
                      <div className="CardBody py-3">
                        <div className="py-2">
                          <div
                            className="commonCardBox p-3"
                            style={{ background: "#f6f6f6" }}
                          >
                            <div className="top py-2">
                              <p className="m-0 fw-sbold">
                                Your appointment has been confirmed. We have
                                also sent you an email confirmation as well.
                              </p>
                              <div className="commonCardBox my-2 p-3 bg-white">
                                <div className="top m-0 py-2">
                                  <h4 className="m-0 fw-sbold">{appointTypeData?.title}</h4>
                                  <p className="m-0">{appointTypeData?.duration} minutes</p>
                                  {(appointTypeData?.location === "video_zoom" && appointTypeData?.video_url) && (
                                    <p>
                                      Video Link:{" "}
                                      <a
                                        href={appointTypeData?.video_url}
                                        target="_blank"
                                      >
                                        {appointTypeData?.video_url}
                                      </a>
                                    </p>
                                  )}
                                  <h6 className="m-0 py-2">
                                    {currentSlot?.start_time} to {currentSlot?.end_time} on {currentDayFromMonth(selectedDate, timeZoneValue)}
                                  </h6>
                                </div>
                              </div>
                            </div>
                            <div className="content">
                              <div className="py-2">
                                <p className="m-0 text-success">
                                  Everything is complete. Your confirmation code
                                  is {bookingResponse && bookingResponse.booking_id}. <br /> Please click the
                                  'Close' button to go back to the main page.
                                </p>
                              </div>
                              <div className="py-2">
                                <div className="btnWrp mt-2 FormbtnWrpper">
                                  <Button
                                    onClick={() => handleChangClose()}
                                    className="d-flex align-items-center justify-content-center commonBtn "
                                  >
                                    Close
                                  </Button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                ) : step == '6' ? (
                  <>
                    <div className="commenContent commonCardBox px-lg-4 p-3 bg-white">
                      <div className="top py-2 border-bottom bg-white ">
                        <h2 className="m-0">
                          Book an Appointment for {clientRes?.first_name}
                        </h2>
                        <p className="m-0 py-2">Finalize your appointment.</p>
                        <ul className="list-unstyled ps-0 mb-0 d-flex align-items-center py-2 gap-10">
                          <li className="">
                            <Button
                              onClick={() => setStep("5")}
                              className="themeLink fw-sbold border-0 p-0"
                              variant="transparent"
                            >
                              Start Over
                            </Button>
                          </li>
                        </ul>
                      </div>
                      <div className="CardBody py-3">
                        <div className="py-2">
                          <div
                            className="commonCardBox p-3"
                            style={{ background: "#f6f6f6" }}
                          >
                            <div className="top py-2">
                              <h4 className="m-0 fw-sbold">{appointTypeData?.title} <span className="text-muted m-0">
                                ({appointTypeData?.duration} minutes)
                              </span></h4>
                              <h6 className="m-0 fw-sbold">
                                {currentSlot?.start_time} to {currentSlot?.end_time} on {currentDayFromMonth(selectedDate, timeZoneValue)}
                              </h6>
                              {appointTypeData?.is_paid_appointment === 'yes' &&
                                <div className="py-2">
                                  <h6 className="m-0">Pay {appointTypeData?.currency_code}{appointTypeData?.price} ({appointTypeData.currency_code}) to finalize your appointment</h6>
                                </div>
                              }
                              Your appointment slot will be held for 15 minutes.

                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  ""
                )}
              </Col>
            </Row>
          </div>
        </Modal.Body>
        <Modal.Footer className="gap-10 FormbtnWrpper">
          <Button
            className="d-flex align-items-center justify-content-center commonBtn btnSm"
            onClick={handleBookAppointment}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>

    </>
  );
};

const mapStateToPros = (state) => {
  return {
    // appointmentTypeList: state.Appointment.appointmentList || [],
  };
};

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(BookAppointmentPop);
