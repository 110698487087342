// default form IDs
export const NAQV1_ID = 12;

export const NAQV2_ID = 4;

export const ACTION_PLAN_FORM_ID = 5;

export const FOOD_JOURNAL_FORM_ID = 21;

export const RECOMMENDATION_FORM_ID = 24;

export const HEALTH_ASSESSMENT_FORM_ID = 11;

export const CLINICAL_PRESENTATION_FORM_ID = 13;

export const EMAIL_REGEX = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,6}$/g;

export const QUESTIONS_PDF_LINK = "https://nutritionalnew.s3.amazonaws.com/subcategory/2024-01-25T07:15:58.277Z.pdf";

export const FOOD_MOOD_DIRECTIONS_PDF = "https://nutritionalnew.s3.amazonaws.com/documents/2024-02-15T08:56:19.414Z.pdf";

export const FOOD_MOOD_DIRECTIONS_LINK = "https://nutritionaltherapyassociation.zendesk.com/hc/en-us/articles/31930555641107-Food-Mood-Journal";

export const HEALTH_COATCH_CAT_ID = "8710fa5f4d31e5dfcffd";

export const PRACTITIONER_CAT_ID = "d895e93465632838e474";

export const BAD_FEELINGS = ["bad", "horrible"];

export const FM_FEELINGS = {
  "great": "Great",
  "good": "Good",
  "so_so": "So So",
  "bad": "Bad",
  "horrible": "Horrible",
  "log_as_a_note": "Log as a note"
}

export const FM_BAD_FEELING_REASONS = {
  "not_sure": "Not Sure",
  "cold_flu": "Cold/Flu",
  "non_food_related_allergies": "Non-food related allergies",
  "other_non_food_related_reason": "Other non-food related reason",
  "something_i_ate_or_drank": "Something I ate or drank",
  "just_ate_way_too_much": "Just ate way too much"
}

export const TIME_FORMAT_24 = "HH:mm";

export const TIME_FORMAT_12 = "h:mm a";

export const helpEmail = "support@nutri-q.com";

export const PASSWORD_VALIDATE_REGEX = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])/;

export const GENERIC_FORM_VALIDATION_ERR = "Form contains errors. Please review the form entries and ensure all inputs provided are correct.";

export const OTP_LENGTH = 4;

export const FULL_ACCOUNT_TYPE = "full_account";

export const DIRECT_ACCOUNT_TYPE = "direct_link";

export const PERMISSION_CONSTANTS = {
  SHOW_CLIENT_CATEGORIES: "show_client_categories",
  SHOW_WISE_WOMAN_PRODUCTS: "show_wise_woman_products",
}

export const CURRENCY_SIGNS = {
  "USD": "$",
  "CAD": "$",
  "AUD": "$",
  "NZD": "$",
  "GBP": "$",
  "EUR": "€",
  "JPY": "¥",
}

export const FMJ_ENTRY_MAX_CHAR = 1000;

export const FMJ_NOTES_MAX_CHAR = 1000;

export const HELP_LINK = "https://nutritionaltherapyassociation.zendesk.com/hc/en-us"